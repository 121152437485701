.policy-page {
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .policy-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: left;
    color: #f32170;
    font-family: 'Poppins';
    margin-top: 5rem;
  }

  .policy-eff-date{
    font-size: 1.25rem;
    font-family: 'Fredoka';
    color: grey;
  }

  .policy-divider {
    border: none;
    border-top: 2px solid black;
    margin: 3rem 0;       /* Space above and below the line */
    max-width: 100%;      /* Full width up to container */
    width: 95%;           /* Slightly indented for readability */
    margin-left: auto;    /* Center the line */
    margin-right: auto;
  }
  
  .policy-section {
    margin-top: 1rem;
    background-color: #d1d9e1;
    padding: 1rem;
    border-radius: 1rem;
  }

  .policy-subhead{
    font-family: 'Roboto';
    font-weight: 600;
    margin-top: 3rem;
  }

  .policy-section p ,li{
    font-family: 'Poppins';
  }
  
  
  .policy-section-head {
    font-size: 1.25rem;
    color: #007bff;
    font-weight: 600;
    margin-top: 2rem;
    font-family: 'Fredoka';
  }

  .policy-section-strong {
    color: #007bff;
    font-weight: 600;
  }
  
  .policy-section p,
  .policy-section ul {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .policy-section ul {
    padding-left: 1.5rem;
  }
  
  .policy-section ul li {
    margin-bottom: 0.5rem;
  }
  
  .policy-section h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 2rem;
    color: #0056b3;
  }
  