.assets-section {
  text-align: center;
  height: fit-content
}

.assets-background{
  background: url("../images/assets.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 2rem;
  margin-bottom:1rem;
}

.landing-search-form {
  opacity: 1;
  margin: 1.5rem;
}

.assets-section .landing-search-bar {
  width: 60%;
  padding: 1.5rem;
  font-size: 1.25rem;
  color: black;
  font-family: monospace;
  margin-bottom: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 4px grey;
}

.landing-dropdown {
  width: 13%;
  padding: 1.5rem;
  font-size: 1.25rem;
  color: grey;
  font-family: monospace;
  margin-bottom: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 4px grey;
}

.assets-section .landing-search-button {
  border: none;
  font-size: 2rem;
  color: black;
  position: relative;
  right: 5rem;
  height: 5px;
  width: 0;
}

.assets-section .landing-search-button:hover{
  color: grey;
  transition: ease-in-out 0.25s;
  background-color: transparent;
}

.asset-scrollable-container{
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 1rem;
  -ms-overflow-style: none;
  scrollbar-width: none; 
  padding-left: 3rem;
  padding-right: 2rem;
  padding-top: 3rem;
}

.landing-scrollable-container::-webkit-scrollbar {
  display: none;
}

.image-row {
  display: flex;
  flex-wrap: nowrap;
}

.image-box {
  width: 20rem;
  background-color: #eaeaea;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: transparent;
}

.image-content {
  width: 100%;
  height: 18rem;
  background-color: #d3d3d3;
  border-radius: 1rem;
  box-shadow: 0px 0px 5px grey;
  background-size: cover;
}

.image-content:hover{
  border: 2px solid lightcoral;
  transition: ease-in-out 0.2s;
}

/* Footer and Sub-footer styles */
.asset-footer {
  font-weight: bold;
  margin-top: 5px;
}

.asset-sub-footer {
  color: gray;
  font-size: 12px;
}

.assets-section .landing-centre-heading{
  font-family: 'Fredoka';
  font-size: 3.25rem;
  margin-top: 7.5rem;
  color: white;
  text-shadow: 3px 3px 5px black;
  padding-left: 15rem;
  padding-right: 15rem;
  font-weight: 900;
}

@media (max-width: 1024px) {
  /* Styles for tablets */
  .assets-section{
    height: 100%;
  }

  .assets-section .landing-centre-heading{
    font-size: 2.25rem;
    padding-left: 0;
    padding-right: 0;
  }

  .assets-section  .landing-search-form {
    margin: 3.5rem 1rem;
    padding-left: 2rem;
  }
  
  .assets-section .landing-search-bar {
    width: 70%;
    font-size: 1.15rem;
  }
  
  .landing-dropdown {
    width: 18%;
    font-size: 1.05rem;
    padding-left: 0.5rem;
  }
  
  .image-box{
    width: 15rem;
    margin: 0.1rem;
  }

  .image-content{
    height: 13rem;
  }

  .asset-scrollable-container{
    padding-left: 2rem;
  }
}

@media (max-width: 767px) {

  .assets-section{
    height: 100%;
  }
  .assets-background{
    background-position-x: -5rem;
  }
  .assets-section .landing-centre-heading{
    font-size: 2rem;
    text-shadow: 0px 7px 3px black;
  }

  .assets-section  .landing-search-form {
    margin: 3.5rem -2rem;
    padding-left: 2rem;
  }

  .image-box{
    width: 15rem;
  }
  .image-content{
    height: 13rem;
  }

  .assets-section .landing-search-bar {
    width: 70%;
    font-size: 1.15rem;
  }
  
  .landing-dropdown {
    width: 22%;
    font-size: 1.05rem;
    padding-left: 0.5rem;
  }

  .asset-scrollable-container{
    padding-left: 1rem;
  }

  .assets-section .landing-search-button {
    font-size: 1.5rem;
    line-height: 2;
  }
}
