@font-face {
  font-family: 'Fredoka';
  src: local('Fredoka'), url('../fonts/Fredoka/Fredoka-VariableFont_wdth\,wght.ttf') format('ttf');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

:focus{
  outline: none;
  outline-offset: 0;
}

  .landing-header-container {
    background: rgba(0, 0, 0, 0.477);
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .landing-header-container .navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: white;
  }

  .header-logo{
    width: 2.95rem;
    height: 2.75rem;
    margin-right: 1rem
  }

  .landing-navbar-title {
    background: linear-gradient(to right,#cf23cf, #ff6b08,  #f32170);
    font-family: "Fredoka";
    font-weight: 900;
    letter-spacing: 1px;
    font-size: 35px;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent; 
  }

  #landing-search{
    margin-left: 2rem;
  }

  .landing-header-icon{
    color:  white;
    font-size: 2.15rem;
  }

  .landing-nav-heading{
    font-family: 'Rowdies';
    font-size: 1.75rem;
    margin-top: 7.5rem;
  }

  .header-text-link{
    color: white;
    font-size: 1.1rem;
    font-family: 'Righteous';
    margin-right: 1rem;
  }

  .header-text-link:hover{
    text-decoration: none;
    transition: ease-in-out 0.25s;
    color: #d7f4ff;
  }

  .header-toggle{
    position: absolute;
    right: 1%;
    border: none;
  }

  .studio-navigation-link{
    text-align: center;
    background: url("../images/canvas-background.png");
    background-size: cover;
    margin: 5rem 10rem;
    height: 50vh;
    border-radius: 2rem;
    opacity: 0.75;
  }

  #studio-link{
    position: relative;
    text-align: center;
    margin-bottom: 10rem;
    background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf);
    padding: 1rem;
    border-radius: 1rem;
    color: white;
    align-content: center;
    width: 100%;
  }


  #studio-link:hover{
    opacity: 0.75;
    transition: ease-in-out 0.35s;
    background-position: right;
  }


  .fixed-waitlist-button {
    position: fixed;
    left: 0;
    bottom: 5rem;
    background-color: #ff14932b;
    color: orangered;
    padding: 1.25rem;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 1000;
    font-family: 'Fredoka';
    font-weight: 800;
    font-size: 1.5rem;
    transition: transform 0.3s ease-in-out;
    animation: pulse 2s infinite, bounce 2s infinite ease-in-out;
}

.fixed-waitlist-button:hover {
    transform: scale(1.05);
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 20, 147, 0.5);
    }
    70% {
        box-shadow: 0 0 30px 15px rgba(255, 20, 147, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 20, 147, 0);
    }
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

  #waitlist-section{
    height: 90vh;
    background: url("../images/watinglist.gif");
    padding-top: 2rem;
    margin-bottom: 5rem;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
  }

  .join-mobile-view{
    height: fit-content;
    background-color: white;
    width: 25%;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    box-shadow: 0px 0px 10px black;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .waitlist-heading{
    font-family: monospace;
    font-weight: 900;
    font-size: 2.25rem;
    text-align: left;
    padding: 2rem;
    padding-bottom: 0.5rem;
  }

  .waitlist-modal .waitlist-heading{
    font-family: 'Fredoka';
    font-weight: 900;
    font-size: 2rem;
    text-align: left;
    padding: 1rem;
    padding-right: 2rem;
    padding-bottom: 0.5rem;
  }

  .waitlist-subheading{
    padding-left: 2rem;
    padding-right: 1rem;
    text-align: left;
    font-family: monospace;
    color: deeppink;
    font-size: 1.1rem;
  }

  .join-waitlist-btn{
    background: orangered;
    border: none;
    box-shadow: 0 0 4px black;
    font-size: 1.25rem;
    font-family: monospace;
    margin-top: 2rem;
    margin-left: 2rem;
  }

  .modal-waitlist-btn{
    background: orangered;
    border: none;
    box-shadow: 0 0 4px black;
    font-size: 1.25rem;
    font-family: monospace;
    padding: 0.5rem;
    margin-left: 30%;
  }

  .waitlist-icon{
    font-size: 2rem;
    font-weight: 700;
  }

  .waitlist-modal .form-control{
    border: none;
    margin-bottom: 1rem;
    background-color: #d7f4ff;
    padding: 1rem;
    box-shadow: 0 0 4px grey;
    font-family: monospace;
    padding-left: 2rem;
  }

  .landing-inspiration{
    display: flex;
    flex-direction: row;
    margin: 5rem;
    height: fit-content;
  }

  .landing-inspiration-text{
    width: 50%;
    text-align: left;
    font-family: 'Righteous';
    align-content: center;
    font-size: 3rem;
  }

  .studio-link-container{
    width: 50%;
    text-align: center;
    align-content: center;
  }

  @media (max-width: 992px) {

    .header-text-links{
      position: absolute;
      background: white;
      top: 4.55rem;
      padding: 1rem;
      right: 0;
      border-bottom-left-radius: 1rem;
      box-shadow: 0px 0px 4px;
      text-align: center;
    }   
    
    .header-text-link{
      color: #f32170;
    }
    #landing-search{
      position: absolute;
      top: 1.3rem;
      right: 12%;
      margin-right: 1.5rem;
    }
    .landing-centre-heading{
      font-size: 2rem;
    }

    #studio-link{
      font-size: 1.35rem;
      width: 90%;
      margin: 3rem;
    }

    .join-mobile-view{
      width: 60%;
    }

    .fixed-waitlist-button{
      font-size: 1.25rem;
    }

    .landing-inspiration{
      flex-direction: column;
    }
    .landing-inspiration-text{
      width: 100%;
      text-align: center;
      font-size: 2rem;
    }
    .studio-link-container{
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .landing-centre-heading{
      font-size: 1.5rem;
    }

    #studio-link{
      font-size: 1.2rem;
      margin: 1rem;
    }

    .join-mobile-view{
      width: 80%;
    }

    .fixed-waitlist-button{
      font-size: 1rem;
      padding: 0.6rem;
    }

    .landing-inspiration{
      margin:3rem; 
      height: 100%;
      flex-direction: column;
      margin-top: 5rem;
      margin-bottom: 5rem;
    }

    .landing-inspiration-text{
      width: 100%;
      text-align: center;
      font-size: 1.5rem;
    }

    .studio-link-container{
      width: 100%;
    }
    .landing-inspiration-container{
      
    }

    .header-logo{
      width: 2.45rem;
      height: 2.3rem;
      margin-right: 0.5rem
    }

    .landing-navbar-title{
      font-size: 27.5px;
    }

    .waitlist-heading{
      font-size: 1.75rem;
    }

    .waitlist-subheading{
      font-size: 1rem;
    }
    
  }