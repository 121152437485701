.footer {
    background-color: white;
    padding: 1rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.footer p{
    font-family: 'Fredoka';
    color: black;
}
  
.footer-image{
    width: 10rem;
    height: auto;
    margin-top: 2rem;
}

 .footer-social-icon{
    font-size: 2rem;
    color: black;
 }

#footer-copyright{
    font-family: 'Fredoka';
    color: #000000b3;
    font-weight: 500;
    margin: 1rem;
    font-size: 1rem;
}

.footer-subheading{
    font-family: 'Fredoka';
    color: blue;
}

.text-reset:hover{
    color: black;
    transition: ease-in-out 0.25s;
}

@media (max-width: 1000px) {
    #footer-copyright{
        font-size: 1.25rem;
    }
    .footer-image{
        width: 10rem;
    }
}

@media (max-width: 767px) {
    #footer-copyright{
        font-size: 1rem;
    }

    .footer-image{
        width: 9rem;
    }
}