.creations-section{
  padding-left: 3rem;
}

.landing-scrollable-container{
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 1rem;
  -ms-overflow-style: none;
  scrollbar-width: none; 
}

.landing-scrollable-container::-webkit-scrollbar {
  display: none;
}

.creative-row {
  display: flex;
  flex-wrap: nowrap;
}

.creative-box {
  width: 20rem;
  margin: 5px;
  background-color: #eaeaea;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: transparent;
}

.creative-profile {
  background-color: aliceblue;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: -3rem;
  left: 5px;
  border-radius: 0.75rem;
  width: 10rem;
}

.creative-content {
  width: 100%;
  height: 18rem;
  background-color: #d3d3d3;
  border-radius: 1rem;
  background-size: cover;
}

.creative-content:hover{
  border: 2px solid lightcoral;
  transition: ease-in-out 0.2s;
}

.creative-details {
  flex-grow: 1;
  padding-left: 10px;
  text-align: left;
}

.creative-footer {
  font-weight: bold;
}

.creative-sub-footer {
  font-size: 0.8rem;
  color: #888;
}

.project-name {
  font-size: 1.15rem;
  width: 100%;
  text-align: center;
  color: deeppink;
  font-family: 'Righteous';
  position: relative;
  top: -2rem;
}

@media (max-width: 1024px) {
  .creative-box{
    width: 16rem;
    margin: 2px;

  }
  .creative-content{
    height: 14rem;
  }

  .creations-section{
    padding-left: 2rem;
  }
  .creative-profile{
    width: 7rem;
  }
}

@media (max-width: 767px) {
  .creative-box{
    width: 12rem;
    margin: 1px;
  }
  .creative-content{
    height: 11rem;
  }

  .creations-section{
    padding-left: 1rem;
  }
  
}
