.portfolio-section {
  text-align: center;
  height: fit-content;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.portfolio-background{
  background: url("../images/portfolio_back.webp");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding-top: 1rem;
  margin-bottom:1rem;
}

.landing-search-form {
  opacity: 1;
  margin: 1.5rem;
}

.landing-search-bar {
  width: 60%;
  padding: 1.5rem;
  font-size: 1.25rem;
  color: black;
  font-family: monospace;
  margin-bottom: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 4px grey;
}

.landing-search-button {
  border: none;
  font-size: 2rem;
  color: black;
  position: relative;
  right: 5rem;
  height: 5px;
  width: 0;
}

.navbar-toggler:focus{
  box-shadow: none;
}

.landing-search-button:hover{
  color: grey;
  transition: ease-in-out 0.25s;
  background-color: transparent;
}

.portfolio-scrollable-container{
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 1rem;
  -ms-overflow-style: none;
  scrollbar-width: none; 
  padding-left: 3rem;
  padding-right: 2rem;
  padding-top: 3rem;
}

.landing-scrollable-container::-webkit-scrollbar {
  display: none;
}


.portfolio-row {
  display: flex;
  flex-wrap: nowrap;
}

.portfolio-box {
  width: 20rem;
  margin: 10px;
  background-color: #eaeaea;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: transparent;
}

.portfolio-image-content {
  width: 100%;
  height: 18rem;
  background-color: #d3d3d3;
  border-radius: 1rem;
  background-size: cover;
}

.portfolio-image-content:hover{
  border: 2px solid lightcoral;
  transition: ease-in-out 0.2s;
}


.portfolio-footer {
  font-weight: bold;
  margin-top: 5px;
}

.portfolio-sub-footer {
  color: gray;
  font-size: 12px;
}

.portfolio-centre-heading{
  font-family: 'Fredoka';
  font-size: 3.25rem;
  margin-top: 6rem;
  margin-bottom: 2rem;
  color: black;
  text-shadow: 3px 3px 5px white;
  padding-left: 15rem;
  padding-right: 15rem;
  font-weight: 900;
}

@media (max-width: 1024px) {
  
  .portfolio-section{
    height: 100%;
    margin-bottom: 0;
  }
  .portfolio-centre-heading{
    font-size: 2.25rem;
    padding-left: 0;
    padding-right: 0;
  }
  .portfolio-section  .landing-search-form {
    margin: 3.5rem 1rem;
    padding-left: 2rem;
  }
  
  .portfolio-section .landing-search-bar {
    width: 70%;
    font-size: 1.15rem;
  }
  
  .portfolio-box{
    width: 15rem;
  }

  .portfolio-image-content{
    height: 13rem;
  }

  .portfolio-scrollable-container{
    padding-left: 2rem;
  }
}

@media (max-width: 767px) {

  .portfolio-section{
    height: 100%;
    margin-bottom: 0;
  }
  .portfolio-centre-heading{
    font-size: 2rem;
  }

  .portfolio-section  .landing-search-form {
    margin: 3.5rem -2rem;
    padding-left: 2rem;
  }

  .portfolio-box{
    width: 15rem;
  }
  .portfolio-image-content{
    height: 13rem;
  }

  .portfolio-section .landing-search-bar {
    width: 70%;
    font-size: 1.15rem;
    margin-left: 1.5rem;
  }

  .portfolio-scrollable-container{
    padding-left: 1rem;
  }
}

