.community-page {
    background: linear-gradient(90deg, rgb(18 5 24), rgb(8 6 31));
    min-height: 100vh;
    color: white;
    overflow-x: hidden;
  }
  
  .community-hero {
    padding: 8rem 0 4rem;
    position: relative;
  }
  
  .community-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
  }
  
  .text-content {
    flex: 1;
  }
  
  .community-title {
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    background: linear-gradient(135deg, #FF1493, #4169E1);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-family: 'Hubot Sans', sans-serif;
  }
  
  .community-subtitle {
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 2rem;
    font-family: 'Fredoka', sans-serif;
    line-height: 1.6;
  }
  
  .app-showcase {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  
  .app-preview {
    position: relative;
    max-width: 400px;
    width: 100%;
  }
  
  .app-preview-image {
    width: 75%;
    height: auto;
    border-radius: 2.5rem;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  }
  
  .app-links {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
  }
  
  .store-button {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 2.5rem 2.5rem;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: all 0.3s ease;
  }
  
  .store-button:hover {
    transform: translateY(-2px);
    background: rgba(255, 255, 255, 0.15);
    border-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .store-icon {
    font-size: 3rem;
  }
  
  .button-text {
    display: flex;
    flex-direction: column;
  }
  
  .button-subtitle {
    font-size: 1rem;
    opacity: 0.8;
  }
  
  .button-title {
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  .features-section {
    padding: 6rem;
    background: transparent;
  }
  
  .features-title {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 4rem;
    background: linear-gradient(135deg, #FF1493, #4169E1);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-family: 'Hubot Sans', sans-serif;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-bottom: 4rem;
  }
  
  .feature-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 1rem;
    padding: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
  }
  
  .feature-card:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.08);
    border-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .feature-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #FF1493;
    font-family: 'Fredoka', sans-serif;
  }
  
  .feature-description {
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.6;
  }
  
  .cta-section {
    text-align: center;
    padding: 4rem 0;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    background: linear-gradient(135deg, #FF1493, #4169E1);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-family: 'Hubot Sans', sans-serif;
  }
  
  .cta-description {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 2rem;
    line-height: 1.6;
  }
  
  .download-button {
    padding: 1rem 3rem;
    font-size: 1.2rem;
    background: linear-gradient(45deg, #FF1493, #FF4500);
    border: none;
    border-radius: 1rem;
    transition: all 0.3s ease;
  }
  
  .download-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(255, 20, 147, 0.3);
  }
  
  @media (max-width: 992px) {
    .community-content {
      flex-direction: column;
      text-align: center;
    }

    .app-links {
      margin-top: 3rem;
      justify-content: center;
    }
  
    .community-title {
      font-size: 3rem;
    }
  
    .app-showcase {
      margin-top: 2rem;
    }
  
    .features-grid {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .features-section{
      padding: 6rem 2rem;
    }
  }
  
  @media (max-width: 768px) {
    .community-hero {
      padding: 6rem 0 3rem;
    }
  
    .community-title {
      font-size: 2.5rem;
    }
  
    .community-subtitle {
      font-size: 1.2rem;
    }
  
    .store-button {
      padding: 1.5rem 0.5rem;
    }

    .store-icon {
      font-size: 1.5rem;
    }
  
    .features-title {
      font-size: 2.5rem;
    }
  
    .cta-title {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 576px) {
    .community-title {
      font-size: 2rem;
    }
  
    .features-grid {
      grid-template-columns: 1fr;
    }
  
    .feature-card {
      padding: 1.5rem;
    }
  
    .cta-section {
      padding: 3rem 1rem;
    }
  }